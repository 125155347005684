.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.logo {
  margin-right: 1vw;
  height: 8vh;
}

.header {
  background-color: #d1d6ce;
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.map {
  width: 100%;
  height: auto;
}

.footer {
  background-color: #d1d6ce;
  min-height: 10vh;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.navbar {
  width: 100%;
}

.navbar ul {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar--link {
  color: #000;
  text-decoration: none;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
